import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const TermsOfUse = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DDSLocator Website | Terms of Use"
          description="By your access and use of the DDSLocator website, ordering products and services, or otherwise transacting with Den-Mat Holdings, LLC, you hereby agree to the terms of our Terms of Use."
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="mt-5 mb-5">Terms of Use</h1>
              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>
                    ATTENTION: PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE
                    USING THIS WEBSITE.
                  </strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  By accessing or using this website, you signify your agreement
                  with these Terms of Use. The Terms of Use are agreed to by and
                  between you and DEN-MAT HOLDINGS, LLC (DENMAT). In
                  consideration for your use of and access to this website, and
                  the promises and obligations in these Terms of Use, and
                  intending to be legally bound, you and DENMAT agree to all of
                  the terms and conditions included herein. Please print a copy
                  of these Terms of Use for your reference. If you do not agree
                  to the following Terms of Use, do not access or use this
                  website, and instead contact DENMAT in writing or via
                  telephone as set forth below for desired information.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  DENMAT reserves the right to modify or amend the Terms of Use
                  at any time. All changes shall be effective immediately upon
                  their posting. Material changes to the Terms of Use will be
                  posted conspicuously on this website. By accessing the website
                  following posting of changes to the Terms of Use, you agree to
                  all such changes.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>DDSLOCATOR:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  DDSLocator connects patients who want to learn more about
                  dental procedures to dentists throughout North America. The
                  information provided is for informational purposes only.
                  Decisions regarding choice of dentist and treatment are a
                  patient's responsibility. DenMat makes no representations or
                  warranties regarding, and shall not be responsible for, the
                  competencies or patient care services or results of any
                  treatment. Dentists provide all information and content
                  contained in DDSLocator. Dentists listed on the DDSLocator may
                  pay a fee for inclusion. No listed dentists are affiliated
                  with DenMat; however, some dentists on this list may purchase
                  products from or provide consulting services to DenMat.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>GENERAL:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  DENMAT authorizes you to view and download the materials at
                  this and all websites owned and operated by DENMAT (Site) only
                  for your personal, non-commercial use, provided that you
                  retain all copyright and other proprietary notices contained
                  in the original materials on any copies of the materials. You
                  may not modify the materials of the Site in any way or
                  reproduce or publicly display, perform, or distribute or
                  otherwise use them for any public or commercial purpose. For
                  purposes of the Terms of Use, any use of these materials on
                  any other website or networked computer environment for any
                  purpose is prohibited. The materials of the Site are
                  copyrighted and any unauthorized use of any materials of the
                  Site may violate copyright, trademark, and other laws. If you
                  breach any of the provisions of the Terms of Use, your
                  authorization to use this Site automatically terminates and
                  you must immediately destroy any downloaded or printed
                  materials. DENMAT also reserves the right to terminate your
                  authorization to use any services of the Site and, where
                  applicable, to delete any one or more of your related access
                  accounts, with or without cause, immediately and at any time.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>INTELLECTUAL PROPERTY RIGHTS:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  All copyrightable text, audio, video, graphics, charts,
                  photographs, icons, and the design, selection, and arrangement
                  of content in any medium on the Site are copyrighted by
                  DENMAT, unless otherwise noted. The distinctive and original
                  layout and presentation of the Site also constitutes
                  protectable trade dress under applicable federal law. In
                  addition, many proprietary names and marks belonging to DENMAT
                  appear throughout this website. The Site may also contain
                  references to third-party marks, and copies of third-party
                  copyrighted materials, which are the property of their
                  respective owners. Any unauthorized use of any trademarks,
                  trade dress, other marks, or any other intellectual property
                  belonging to DENMAT or any third party is strictly prohibited
                  and may be prosecuted to the fullest extent of the law.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>USE OF SOFTWARE:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  If you download software from the Site (Software), use of the
                  Software is subject to the license terms in the Software
                  License Agreement that accompanies or is provided with the
                  Software. You may not download or install the Software until
                  you have read and accepted the terms of the Software License
                  Agreement. Any download of software is at your own risk;
                  DENMAT is not responsible or liable for any viruses, malware,
                  bugs, or any other software anomalies.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>PRIVACY POLICY:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  By your access and use of the Site, you hereby agree to the
                  terms of the DENMAT Privacy Policy, which are incorporated
                  into these Terms of Use by this reference, and which policy is
                  available under “Privacy Policy” on the Site.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>
                    BULLETIN BOARDS, CHAT ROOMS AND OTHER INTERACTIVE AREAS:
                  </strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  DENMAT may provide bulletin boards, chat rooms and other
                  interactive areas for its visitors to participate in. By
                  accessing or using the Site, you agree to abide by the
                  following guidelines for use of the bulletin boards, chat
                  rooms and other interactive areas of this website:
                </span>
              </p>

              <ol>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      You must maintain a polite, pleasant environment. Comments
                      that harass, abuse or threaten others are prohibited. You
                      may not criticize other individual users or DENMAT or any
                      of its affiliates.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      You must ensure that all comments are appropriate to all
                      people. Any content that is offensive, defamatory,
                      pornographic, obscene, or otherwise objectionable is
                      prohibited.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      You must keep your comments relevant to the stated topic.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      Any conduct that in DENMAT's sole discretion restricts
                      others from using or enjoying the Site is prohibited.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      You may not advertise a business or service.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      Copyrighted material may only be posted with permission of
                      the author.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      Harvesting or collecting information about others,
                      including e-mail addresses, is prohibited.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      You may not send any chain letters, junk mail,
                      unauthorized e-mail, or commercial solicitations, or to
                      engage in, facilitate or encourage any illegal activities.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      All users of the Site must also follow any other
                      guidelines or rules provided by DENMAT or posted on the
                      Site. A failure to comply with any of these guidelines or
                      rules, or any of the other provisions of the Terms of Use
                      and conditions contained therein, will result in, among
                      other possible action, the immediate termination of all
                      membership and privileges to post content to the website.
                      Please report any violations of these guidelines to
                      DENMAT, as provided for in the CONTACT DEN-MAT HOLDINGS,
                      LLC paragraph of the Terms of Use.
                    </span>
                  </p>
                </li>
              </ol>

              <p>
                <span style={{ fontSize: `18px` }}>
                  You and other users of the DENMAT bulletin boards, chat rooms
                  and other interactive areas are solely responsible for the
                  contents and consequences of the messages you communicate or
                  post on this website. You should exercise the utmost
                  discretion before providing any personal information on the
                  Site. Although DENMAT is not obligated to monitor content or
                  the accuracy of information, you acknowledge and agree that
                  DENMAT, in its sole discretion, has the right to monitor,
                  without notice, any such content or information posted on the
                  Site. DENMAT also reserves the right, in its sole and absolute
                  discretion, to prohibit or remove any conduct or content,
                  without notice, for any reason, and to revoke any user’s
                  membership, for any reason.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>YOUR ACCOUNT:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  If you use the Site, you are responsible for maintaining the
                  confidentiality of your account including you login and
                  password information and for restricting access to your
                  computer, and you agree to accept responsibility for all
                  activities that occur under your account or with use of your
                  login and password. DENMAT does sell products for children,
                  but does so only through adults, who can make purchases with a
                  credit card or other permitted payment method. If you are
                  under 18, you may use the Site only with permission of a
                  parent or guardian. DENMAT reserves the right to refuse
                  service, terminate accounts, remove or edit content, or cancel
                  orders in its sole discretion.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>RISK OF LOSS:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  All items purchased from DENMAT are made pursuant to a
                  shipment contract. This means that the risk of loss and title
                  for such items pass to you upon our delivery to the carrier.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>PRODUCT DESCRIPTIONS:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  DENMAT attempts to be as accurate as possible. However, DENMAT
                  does not warrant that product descriptions or other content on
                  the Site is accurate, complete, reliable, current, or
                  error-free. If a product offered by DENMAT itself is not as
                  described, your sole remedy is to return it in unused
                  condition.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  DENMAT respects the rights of intellectual property owners,
                  and asks that its users do the same. If you believe that your
                  work has been copied in a way that constitutes infringement,
                  please provide DENMAT the following information, in the form
                  prescribed by Section 512 of Title 17, United States Code:
                </span>
              </p>

              <ol>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      an electronic or physical signature of a person authorized
                      to act on behalf of the owner of an exclusive right that
                      is allegedly infringed;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      a description of the copyrighted work or works that you
                      claim have been infringed;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      a description of the allegedly infringing material,
                      including its location on the website;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      your address, telephone number, and e-mail address;
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      a statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law; and
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontSize: `18px` }}>
                      a statement by you, made under penalty of perjury, that
                      the above information in your notice is accurate and that
                      you are the copyright owner or authorized to act on the
                      copyright owner's behalf.
                    </span>
                  </p>
                </li>
              </ol>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>COPYRIGHT AGENT</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  For notice of claims of copyright infringement on denmat.com.
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>DenMat</span>
                <br />
                <span style={{ fontSize: `18px` }}>Attn: General Counsel</span>
                <br />
                <span style={{ fontSize: `18px` }}>P.O. Box 1729</span>
                <br />
                <span style={{ fontSize: `18px` }}>Lompoc, CA 93438-1729</span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  You and DENMAT understand and agree that: (1) DENMAT assumes
                  no liability or responsibility for any content or material of
                  any kind that is submitted for or posted on any interactive
                  area on the Site (including without limitation any bulletin
                  boards, chat rooms, surveys on the website) by you or by any
                  other users or third parties and (2) DENMAT is a publisher of
                  any such content, and is acting solely as an Internet Service
                  Provider as such term is defined in the Digital Millennium
                  Copyright Act.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>LINKS TO THIRD PARTY WEBSITES:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  Links to third party websites on the Site are provided solely
                  as a convenience to you. If you use these links, you will
                  leave this Site. DENMAT has not reviewed all of these third
                  party sites and does not control and is not responsible for
                  any of these sites or their content. Thus, DENMAT does not
                  endorse or make any representations about them, or any
                  information, software or other products or materials found
                  there, or any results that may be obtained from using them. If
                  you decide to access any of the third party sites linked to
                  the Site, you do this entirely at your own risk.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  DENMAT consents to links to the Site in which the link and the
                  pages that are activated by the link do not: (a) create frames
                  around any page on this Web site or use other techniques that
                  alter in any way the visual presentation or appearance of any
                  content within the Site; (b) misrepresent your relationship
                  with DENMAT; (c) imply that DENMAT approves or endorses you,
                  your web site, or your service or product offerings; and (d)
                  present false or misleading impressions about DENMAT or
                  otherwise damage the goodwill associated with the DENMAT name
                  or trademarks. As a further condition to being permitted to
                  link to the Site, you agree that DENMAT may at any time, in
                  its sole discretion, terminate permission to link to the Site.
                  In such event, you agree to immediately remove all links to
                  the Site and to cease using any DENMAT trademark.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>NO WARRANTY:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  THE MATERIALS, INFORMATION, AND SERVICES PROVIDED AT THE SITE
                  ARE PROVIDED AS IS WITHOUT ANY WARRANTIES OF ANY KIND
                  INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL
                  PROPERTY. DENMAT further does not warrant the accuracy and
                  completeness of the materials, information, or services found
                  at the Site. DENMAT may make changes to the materials,
                  information, and services at the Site, or to the products and
                  prices described in them, at any time without notice. The
                  materials, information, and services at thie Site may be out
                  of date, and DENMAT makes no commitment to update the
                  materials, information, or services at the Site. Information
                  published at the Site may refer to products, programs or
                  services that are not available in your geographic area or
                  country. No advice or information, whether oral or written,
                  obtained by you from DENMAT or in any manner from this website
                  shall create any warranty.{' '}
                </span>
                <br />
                <br />
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>LIMITATION OF LIABILITY:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  IN NO EVENT WILL DENMAT, ITS SUPPLIERS, OR OTHER THIRD PARTIES
                  MENTIONED AT THE SITE BE LIABLE FOR ANY DAMAGES WHATSOEVER
                  (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST
                  PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF
                  THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF THE SITE,
                  ANY WEB SITES LINKED TO THE SITE, OR THE MATERIALS OR
                  INFORMATION OR SERVICES CONTAINED AT ANY OR ALL SUCH SITES,
                  WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL
                  THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES. IF YOUR USE OF THE MATERIALS, INFORMATION OR SERVICES
                  FROM THIS SITE RESULTS IN THE NEED FOR SERVICING, REPAIR OR
                  CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ALL COSTS THEREOF.
                  YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE YOUR ACCESS
                  TO AND USE OF THE WEBSITE.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
                  OR EXCLUSION MAY NOT APPLY TO YOU.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>INDEMNIFICATION:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  You agree to indemnify, defend and hold harmless DENMAT, its
                  officers, representatives, directors, employees, consultants
                  and agents from any and all losses, expenses, third-party
                  claims, liabilities, damages and costs (including without
                  limitation attorneys’ fees) arising from or related to your
                  use of the Site, your use of any material, information or data
                  downloaded or otherwise obtained from the Site, or your
                  violation of the Terms of Use, including without limitation,
                  your infringement of any intellectual property or other right
                  of DENMAT or any other person or entity.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>INTERPRETATION OF DISPUTES:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  The Terms of Use are governed by the laws of the United States
                  and the State of California, without regard to any conflict of
                  laws provisions. Venue shall be proper exclusively in the
                  County of Santa Barbara, California with respect to any
                  dispute arising under the Terms of Use, unless otherwise
                  specifically agreed by DENMAT in its sole discretion. In the
                  event of any dispute, the prevailing party shall be entitled
                  to recovery of its reasonable attorneys’ fees and costs. If
                  any provision of the Terms of Use is deemed unenforceable or
                  invalid by a court or arbitrator, then the court or arbitrator
                  shall modify such provision to the minimum extent necessary to
                  make such provision enforceable and valid. Should such
                  modification prove impossible or impracticable then the
                  provision shall be severed and the remaining terms of the
                  Terms of Use shall be interpreted and read to give them
                  maximum enforceability. Any cause of action or claim with
                  respect to this website must be commenced within one (1) year
                  after the action or claim arises.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>ENTIRE AGREEMENT:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  By your access or use of the Site, you hereby agree to the
                  Terms of Use. The Terms of Use, together with the Privacy
                  Policy constitute the entire agreement between you and DENMAT
                  with respect to your access and use of the Site and supersede
                  all prior or contemporaneous understandings or agreements,
                  written or oral, regarding such subject matter. Any waiver of
                  any provision of the Terms of Use will be effective only if in
                  writing and signed by DENMAT. The Terms of Use will inure to
                  the benefit of DENMAT’s successors and assigns.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>ELECTRONIC COMMUNICATIONS:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  You acknowledge that the Terms of Use constitute a valid and
                  binding agreement. To the fullest extent permitted by law, you
                  agree that the Terms of Use and any other documentation,
                  agreements, notices or communications between you and DENMAT
                  may be provided to you electronically. Please print a copy of
                  all such documentation, agreements, notices or other
                  communications for your reference.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>CONTACT DEN-MAT HOLDINGS, LLC:</strong>
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>DenMat</span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  P.O. Box 1729, Lompoc, CA 93438-1729
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>800-228-5595</span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  <a href="mailto:info@denmat.com">info@denmat.com</a>
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  (Revised Form 18-Mar-2021)
                </span>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermsOfUse
